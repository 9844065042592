@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");

:root {
    --primary-color: #484848;
    --bg-color: #009cde;
    --white-color: #ffffff;
    --btn-color: #37b24a;
    --sticky-header-color: #f5f0f0;
    --color-333333: #333333;
    --color-3a3b40: #3a3b40;
    --color-999999: #999999;
    --color-edfaff: #edfaff;
}

body {
    font-family: "Source Sans Pro" !important;
}

@media (min-width: 992px) and (max-width:1199.98px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 1025px !important;
    }
}
